*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background-color: #fff2b0;
  color: #225500;
}

body::before {
  content: "";
  background-image: radial-gradient(ellipse at 0% 100%, #224430 10%, transparent 50%), radial-gradient(ellipse at -30% 80%, transparent 50%, #ffd610 70%, transparent 90%);
  min-height: 100vh;
  background-position: 80% 100%;
  transition: background-size 0.5s ease-in-out;
  background-size: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

body.quiz-active {
  background-size: 10000%;
}

h1 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

h1 .level-indicator {
  font-weight: 300;
  padding-left: 2rem;
}

h2 {
  font-size: 1.75rem;
  font-weight: 700;
}

p {
  font-size: 1.125rem;
}

.cutouts {
  position: fixed;
  inset: 0;
  opacity: 0.2;
}

.cutouts .cutout {
  position: absolute;
  transition: transform 1s ease-in-out;
  transform-origin: 50% 50%;
}

.cutouts .cutout img {
  transform-origin: 50% 50%;
  animation: cutoutRandomize 5s steps(2) infinite;
}

.cutouts .cutout-ugle1 {
  bottom: 0;
  left: 0;
  transform: rotate(-20deg) translate(-80%, 80%);
}

.cutouts .cutout-ugle1.active {
  transform: none;
}

.cutouts .cutout-ugle2 {
  bottom: 0;
  right: 0;
  transform: rotate(20deg) translate(80%, 80%);
}

.cutouts .cutout-ugle2 img {
  transform-origin: 90% 90%;
}

.cutouts .cutout-ugle2.active {
  transform: translate(5%, 5%);
}

.cutouts .cutout-and {
  bottom: 0;
  right: 0;
  max-width: 20rem;
  transform: rotate(20deg) translate(80%, 80%);
}

.cutouts .cutout-and img {
  transform-origin: 50% 50%;
  max-width: 100%;
  height: auto;
}

.cutouts .cutout-and.active {
  transform: translate(5%, 5%);
}

.cutouts .cutout-sael {
  bottom: 0;
  right: 0;
  max-width: 24rem;
  transform: rotate(20deg) translate(80%, 80%);
}

.cutouts .cutout-sael img {
  transform-origin: 90% 90%;
  max-width: 100%;
  height: auto;
}

.cutouts .cutout-sael.active {
  transform: translate(5%, 5%);
}

.cutouts .cutout-egern {
  bottom: 0;
  right: 0;
  max-width: 20rem;
  transform: rotate(20deg) translate(80%, 80%);
}

.cutouts .cutout-egern img {
  transform-origin: 90% 90%;
  max-width: 100%;
  height: auto;
}

.cutouts .cutout-egern.active {
  transform: translate(5%, 5%);
}

.page-wrapper {
  position: absolute;
  padding: 2rem;
  margin: 0 auto;
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  max-width: 75rem;
  min-height: 100%;
  width: 100%;
  left: 50%;
  transform: translate3d(-50%, -3%, 0);
  transition: all 0.3s ease;
  opacity: 0;
}

.page-wrapper.active {
  visibility: visible;
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
  transition: all 0.8s ease;
}

.page-wrapper .content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
}

.page-wrapper .content .text-content {
  width: 100%;
}

.page-wrapper .content h2 {
  margin-bottom: 2rem;
}

.page-wrapper .content .content-link {
  margin-top: 2rem;
  color: #ffd610;
  font-weight: 700;
  font-size: 1.25rem;
  display: inline-flex;
  align-items: center;
}

.page-wrapper .content .content-link::after {
  display: block;
  margin-left: 1rem;
  content: url("icon-play.9740bb4b.svg");
}

.page-wrapper .content img {
  width: 8rem;
  margin: 3rem;
}

.page-wrapper footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.page-wrapper footer .footer-link {
  margin-bottom: 1rem;
  color: #224430;
  font-weight: 700;
  font-size: 1.25rem;
}

.page-wrapper footer .btn {
  margin-top: 2rem;
}

.page-wrapper .content-levels {
  flex-direction: column !important;
}

.page-wrapper .content-levels img {
  margin: 0;
}

.content-levels header,
.content-subject header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.content-levels header h3,
.content-subject header h3 {
  font-size: 1.25rem;
  font-weight: 700;
}

.content-levels h2,
.content-subject h2 {
  font-size: 1.75rem;
}

.step {
  display: none;
}

.step header h2 {
  font-size: 2rem;
  font-weight: 700;
}

.step header span.total {
  font-weight: 300;
}

.step.active {
  display: block;
}

.media-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
}

.media-wrapper img {
  max-width: 100%;
  height: auto;
}

.media-wrapper iframe {
  border-radius: 0.5rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.status-bar {
  border-radius: 0.35rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 100%;
  height: 0.625rem;
  margin: 1rem 0 3rem 0;
  /* ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    flex: 1;
    padding: 0.5rem;
    background-color: $brandGreen;
    text-indent: -1000rem;
    height: 1rem;
    transition: background-color 0.2s ease;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:hover,
    &.active {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;

      & ~ li {
        background-color: $brandWhite;
      }
    }

    &:hover {
      background-color: $brandYellow;
    }
  } */
}

.status-bar .status-bar-progress {
  height: 0.625rem;
  border-radius: 0.35rem;
  background-color: #224430;
}

.question {
  position: relative;
  z-index: 10;
  padding: 2rem 3rem;
  height: 100%;
  min-height: 20rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
}

.question a {
  color: inherit;
}

.question h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.question .btn-correct,
.question .btn-wrong {
  position: relative;
  width: 100%;
  margin-bottom: 0.5rem;
  min-height: 3.75rem;
  line-height: 1.5;
  border-radius: 1.875rem;
  padding: 1rem 2rem;
}

.question .btn-correct.disabled {
  opacity: 1;
}

.question .btn-correct::after {
  content: "";
  position: absolute;
  top: 0;
  right: 1rem;
  width: 1.5rem;
  height: 100%;
  background-image: url("icon-correct.8fe30171.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transform-origin: 50% 50%;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
  transform: rotate(30deg);
}

.question .btn-wrong::after {
  content: "";
  position: absolute;
  top: 0;
  right: 1rem;
  width: 1.5rem;
  height: 100%;
  background-image: url("icon-wrong.a9a5e65f.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transform-origin: 50% 50%;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  opacity: 0;
  transform: rotate(30deg);
}

.question .btn-check:checked + .btn:after,
.question .btn-check:active + .btn::after {
  opacity: 1;
  transform: none;
}

.question .btn-check:checked + .btn-wrong,
.question .btn-check:active + .btn-wrong {
  color: #224430;
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}

.question img {
  position: absolute;
  inset: 0;
  z-index: -1;
  width: calc(100% + 3rem);
  height: 100%;
  min-height: 20rem;
  max-width: 100%;
  object-fit: fill;
  object-position: center;
}

#reset-button {
  position: relative;
  font-weight: 700;
  color: #fff;
}

#reset-button::before {
  content: "";
  position: absolute;
  width: 62px;
  height: 96px;
  background-image: url("reset-toilet.03ce0632.png");
  background-size: contain;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  animation: cutoutRandomize 5s 0.33s steps(2) infinite;
  right: 90%;
  top: -20px;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

#reset-button:hover::before {
  opacity: 1;
}

.form-select {
  background-color: transparent;
  border: 3px solid #224430;
  border-radius: 3rem;
  color: #224430;
  min-width: 10rem;
  width: auto;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1rem 2rem;
}

.btn {
  min-width: 10rem;
  border-radius: 3rem;
  padding: 1rem 2rem;
}

.btn-primary {
  color: #ffd610;
  background-color: #224430;
  border-color: #224430;
}

.btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 68, 48, 0.5);
  color: #ffd610;
  background-color: #224430;
  border-color: #224430;
}

.btn-primary.hover, .btn-primary:hover, .btn-primary.active, .btn-primary:active, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active {
  color: #ffd610;
  background-color: #1f3d2b;
  border-color: #224430;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 68, 48, 0.5);
}

.btn-outline-primary {
  color: #224430;
  border-color: #224430;
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 68, 48, 0.5);
}

.btn-outline-primary.hover, .btn-outline-primary:hover, .btn-outline-primary.active, .btn-outline-primary:active, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary.dropdown-toggle.show {
  color: #ffd610;
  background-color: #1f3d2b;
  border-color: #224430;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(34, 68, 48, 0.5);
}

.btn-outline-primary .btn-check:checked + .btn-outline-primary:focus,
.btn-outline-primary .btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary .btn-outline-primary:active:focus,
.btn-outline-primary .btn-outline-primary.active:focus,
.btn-outline-primary .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #224430;
  background-color: transparent;
}

.btn-link {
  color: #224430;
  text-decoration: none;
}

.btn-link.hover, .btn-link:hover, .btn-link.active, .btn-link:active, .btn-link:not(:disabled):not(.disabled):active, .btn-link:not(:disabled):not(.disabled).active, .btn-check:checked + .btn-link, .btn-check:active + .btn-link, .btn-link.dropdown-toggle.show {
  color: #1f3d2b;
}

@keyframes cutoutRandomize {
  0%, 100% {
    transform: rotate(0deg);
  }
  20%, 60% {
    transform: rotate(-4deg);
  }
  40%, 80% {
    transform: rotate(4deg);
  }
}

@media (min-width: 65rem) and (min-height: 50rem) {
  .cutouts {
    opacity: 1;
  }

  .page-wrapper .content {
    flex-direction: column;
  }
  .page-wrapper footer {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-end;
  }
  .page-wrapper footer .footer-link {
    margin-bottom: 0;
  }
}

@media (min-width: 65rem) {
  .page-wrapper .content {
    align-items: flex-start;
    flex-direction: row-reverse;
    justify-content: space-between;
    height: 500px;
  }
  .page-wrapper .content .text-content {
    width: 70%;
  }
  .page-wrapper .content .text-content h2 {
    font-size: 5rem;
  }
  .page-wrapper .content .text-content p {
    font-size: 1.5rem;
  }
  .page-wrapper .content h2 {
    font-size: 5rem;
  }
  .page-wrapper .content img {
    margin: 0;
  }
  .page-wrapper .content-levels {
    justify-content: unset;
    flex-direction: column;
  }
  .page-wrapper .content-levels header {
    margin-bottom: 4rem;
  }
  .page-wrapper .content-levels img {
    margin: 3rem;
  }
  .page-wrapper .footer-levels {
    justify-content: flex-end;
  }
  .page-wrapper .text-content h2 {
    font-size: 5rem;
  }
  .page-wrapper .text-content p {
    font-size: 1.5rem;
  }

  .content-wrapper {
    width: 2000px;
  }
  .content-wrapper a {
    color: inherit;
  }
}
/*# sourceMappingURL=index.ca8617ce.css.map */
