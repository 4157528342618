$brandGreen: #224430;
$brandYellow: #ffd610;
$brandYellowLight: #fff2b0;
$brandWhite: #ffffff;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  background-color: $brandYellowLight;
  color: #225500;

  &::before {
    content: "";
    background-image: radial-gradient(ellipse at 0% 100%, $brandGreen 10%, transparent 50%),
    radial-gradient(ellipse at -30% 80%, transparent 50%, $brandYellow 70%, transparent 90%);
    min-height: 100vh;
    background-position: 80% 100%;
    transition: background-size 0.5s ease-in-out;
    background-size: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }


  &.quiz-active {
    background-size: 10000%;
  }
}

h1 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 2rem;

  .level-indicator {
    font-weight: 300;
    padding-left: 2rem;
  }
}

h2 {
  font-size: 1.75rem;
  font-weight: 700;
}

p {
  font-size: 1.125rem;
}

.cutouts {
  position: fixed;
  inset: 0;
  opacity: 0.2;

  .cutout {
    position: absolute;
    transition: transform 1s ease-in-out;
    transform-origin: 50% 50%;

    img {
      transform-origin: 50% 50%;
      animation: cutoutRandomize 5s steps(2) infinite;
    }
  }

  .cutout-ugle1 {
    bottom: 0;
    left: 0;
    transform: rotate(-20deg) translate(-80%, 80%);

    &.active {
      transform: none;
    }
  }

  .cutout-ugle2 {
    bottom: 0;
    right: 0;
    transform: rotate(20deg) translate(80%, 80%);

    img {
      transform-origin: 90% 90%;
    }

    &.active {
      transform: translate(5%, 5%);
    }
  }

  .cutout-and {
    bottom: 0;
    right: 0;
    max-width: 20rem;
    transform: rotate(20deg) translate(80%, 80%);

    img {
      transform-origin: 50% 50%;
      max-width: 100%;
      height: auto;
    }

    &.active {
      transform: translate(5%, 5%);
    }
  }

  .cutout-sael {
    bottom: 0;
    right: 0;
    max-width: 24rem;
    transform: rotate(20deg) translate(80%, 80%);

    img {
      transform-origin: 90% 90%;
      max-width: 100%;
      height: auto;
    }

    &.active {
      transform: translate(5%, 5%);
    }
  }

  .cutout-egern {
    bottom: 0;
    right: 0;
    max-width: 20rem;

    transform: rotate(20deg) translate(80%, 80%);

    img {
      transform-origin: 90% 90%;
      max-width: 100%;
      height: auto;
    }

    &.active {
      transform: translate(5%, 5%);
    }
  }
}

.page-wrapper {
  position: absolute;
  padding: 2rem;
  margin: 0 auto;
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  max-width: 75rem;
  min-height: 100%;
  width: 100%;
  left: 50%;
  transform: translate3d(-50%, -3%, 0);
  transition: all .3s ease;
  opacity: 0;

  &.active {
    visibility: visible;
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
    transition: all .8s ease;
  }

  .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .text-content {
      width: 100%;
    }

    h2 {
      margin-bottom: 2rem;
    }

    .content-link {
      margin-top: 2rem;
      color: $brandYellow;
      font-weight: 700;
      font-size: 1.25rem;
      display: inline-flex;
      align-items: center;

      &::after {
        display: block;
        margin-left: 1rem;
        content: url("../images/icon-play.svg");
      }
    }

    img {
      width: 8rem;
      margin: 3rem;
    }
  }

  footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .footer-link {
      margin-bottom: 1rem;
      color: $brandGreen;
      font-weight: 700;
      font-size: 1.25rem;
    }

    .btn {
      margin-top: 2rem;
    }
  }

  .content-levels {
    flex-direction: column !important;

    img {
      margin: 0;
    }
  }
}

.content-levels,
.content-subject {
  header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h3 {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  h2 {
    font-size: 1.75rem;
  }
}

.step {
  display: none;

  header {
    h2 {
      font-size: 2rem;
      font-weight: 700;
    }

    span.total {
      font-weight: 300;
    }
  }

  &.active {
    display: block;
  }
}

.media-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;

  img {
    max-width: 100%;
    height: auto;
  }

  iframe {
    border-radius: 0.5rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.status-bar {
  border-radius: 0.35rem;
  background-color: $brandWhite;
  overflow: hidden;
  width: 100%;
  height: 0.625rem;
  margin: 1rem 0 3rem 0;

  .status-bar-progress {
    height: 0.625rem;
    border-radius: 0.35rem;
    background-color: $brandGreen;
  }

  /* ul {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    position: relative;
    flex: 1;
    padding: 0.5rem;
    background-color: $brandGreen;
    text-indent: -1000rem;
    height: 1rem;
    transition: background-color 0.2s ease;

    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:hover,
    &.active {
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;

      & ~ li {
        background-color: $brandWhite;
      }
    }

    &:hover {
      background-color: $brandYellow;
    }
  } */
}

.question {
  position: relative;
  z-index: 10;
  padding: 2rem 3rem;
  height: 100%;
  min-height: 20rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;

  a {
    color: inherit;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }

  .btn-correct,
  .btn-wrong {
    position: relative;
    width: 100%;
    margin-bottom: 0.5rem;
    min-height: 3.75rem;
    line-height: 1.5;
    border-radius: 1.875rem;
    padding: 1rem 2rem;
  }

  .btn-correct {
    &.disabled {
      opacity: 1;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 1rem;
      width: 1.5rem;
      height: 100%;
      background-image: url("../images/icon-correct.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      transform-origin: 50% 50%;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
      opacity: 0;
      transform: rotate(30deg);
    }
  }

  .btn-wrong {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 1rem;
      width: 1.5rem;
      height: 100%;
      background-image: url("../images/icon-wrong.svg");
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      transform-origin: 50% 50%;
      transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
      opacity: 0;
      transform: rotate(30deg);
    }
  }

  .btn-check:checked + .btn:after,
  .btn-check:active + .btn::after {
    opacity: 1;
    transform: none;
  }

  .btn-check:checked + .btn-wrong,
  .btn-check:active + .btn-wrong {
    color: $brandGreen;
    background-color: #bdbdbd;
    border-color: #bdbdbd;
  }

  img {
    position: absolute;
    inset: 0;
    z-index: -1;
    width: calc(100% + 3rem);
    height: 100%;
    min-height: 20rem;
    max-width: 100%;
    object-fit: fill;
    object-position: center;
  }
}

#reset-button {
  position: relative;
  font-weight: 700;
  color: #fff;

  &::before {
    content: "";
    position: absolute;
    width: 62px;
    height: 96px;
    background-image: url("../images/reset-toilet.png");
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    animation: cutoutRandomize 5s 0.33s steps(2) infinite;
    right: 90%;
    top: -20px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  &:hover {
    &::before {
      opacity: 1;
    }
  }
}

// Forms

.form-select {
  background-color: transparent;
  border: 3px solid $brandGreen;
  border-radius: 3rem;
  color: $brandGreen;
  min-width: 10rem;
  width: auto;
  font-size: 1.25rem;
  font-weight: 700;
  padding: 1rem 2rem;
}

.btn {
  min-width: 10rem;
  border-radius: 3rem;
  padding: 1rem 2rem;
}

.btn-primary {
  color: $brandYellow;
  background-color: $brandGreen;
  border-color: $brandGreen;

  &:focus {
    box-shadow: 0 0 0 0.2rem transparentize($brandGreen, 0.5);
    color: $brandYellow;
    background-color: $brandGreen;
    border-color: $brandGreen;
  }

  &.hover,
  &:hover,
  &.active,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $brandYellow;
    background-color: darken($brandGreen, 2%);
    border-color: $brandGreen;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem transparentize($brandGreen, 0.5);
  }
}

.btn-outline-primary {
  color: $brandGreen;
  border-color: $brandGreen;

  .btn-check:focus + &,
  &:focus {
    box-shadow: 0 0 0 0.2rem transparentize($brandGreen, 0.5);
  }

  &.hover,
  &:hover,
  &.active,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .btn-check:checked + &,
  .btn-check:active + &,
  &.dropdown-toggle.show {
    color: $brandYellow;
    background-color: darken($brandGreen, 2%);
    border-color: $brandGreen;
  }

  &:not(:disabled):not(.disabled):active:focus,
  &:not(:disabled):not(.disabled).active:focus {
    box-shadow: 0 0 0 0.2rem transparentize($brandGreen, 0.5);
  }

  .btn-check:checked + .btn-outline-primary:focus,
  .btn-check:active + .btn-outline-primary:focus,
  .btn-outline-primary:active:focus,
  .btn-outline-primary.active:focus,
  .btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
  }

  &.disabled,
  &:disabled {
    color: $brandGreen;
    background-color: transparent;
  }
}

.btn-link {
  color: $brandGreen;
  text-decoration: none;

  &.hover,
  &:hover,
  &.active,
  &:active,
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .btn-check:checked + &,
  .btn-check:active + &,
  &.dropdown-toggle.show {
    color: darken($brandGreen, 2%);
  }
}

@keyframes cutoutRandomize {
  0%,
  100% {
    transform: rotate(0deg);
  }

  20%,
  60% {
    transform: rotate(-4deg);
  }

  40%,
  80% {
    transform: rotate(4deg);
  }
}

@media (min-width: 65rem) and (min-height: 50rem) {
  .cutouts {
    opacity: 1;
  }

  .page-wrapper {
    .content {
      flex-direction: column;//column-reverse;
    }

    footer {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;

      .footer-link {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 65rem) {
  .page-wrapper {
    .content {
      align-items: flex-start;
      flex-direction: row-reverse;
      justify-content: space-between;
      height: 500px;

      .text-content {
        width: 70%;

        h2 {
          font-size: 5rem;
        }

        p {
          font-size: 1.5rem;
        }
      }

      h2 {
        font-size: 5rem;
      }

      img {
        margin: 0;
      }
    }

    .content-levels {
      justify-content: unset;
      flex-direction: column;

      header {
        margin-bottom: 4rem;
      }

      img {
        margin: 3rem;
      }
    }

    .footer-levels {
      justify-content: flex-end;
    }

    .text-content {
      h2 {
        font-size: 5rem;
      }

      p {
        font-size: 1.5rem;
      }
    }
  }

  .content-wrapper {
    width: 2000px;

    a {
      color: inherit;
    }
  }
}
